<template>
    <div>
        <breadcrumb
            name="News"
            title="News List"
            subtitle="Add"
            @onTitleClicked="backToList"
        />
        <div class="body">
            <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
            <div>
                <div style="float: right">
                    <h6 class="header">Show On Mobile</h6>
                    <vs-switch v-model="mobile_active" />
                </div>
                <div class="clr"></div>
            </div>
            <div>
                <div class="english">
                    <vs-input
                        label="Title English"
                        v-model="title_en"
                        size="medium"
                        class="input"
                    />
                    <small class="label">Description English</small>
                    <ckeditor
                        :editor="editor"
                        v-model="text_en"
                        :config="editorConfigEn"
                        class="input"
                        v-if="!showHTML"
                    />
                    <textarea v-model="text_en" v-if="showHTML"></textarea>
                </div>
                <div class="arabic">
                    <vs-input
                        label="Title Arabic"
                        v-model="title_ar"
                        size="medium"
                        class="input"
                    />
                    <small class="label">Description Arabic</small>
                    <ckeditor
                        :editor="editor"
                        v-model="text_ar"
                        :config="editorConfigAr"
                        class="input"
                        v-if="!showHTML"
                    />
                    <textarea v-model="text_ar" v-if="showHTML"></textarea>
                </div>
            </div>
            <div>
                <div class="clr" />
                <div class="settings">
                    <div class="inline-block" style="width: 490px">
                        <h6 class="header">Related to a brand</h6>
                        <vs-switch v-model="type" />
                        <div v-if="type">
                            <label for="brands">Choose Brand</label>
                            <select
                                class="vs-select"
                                id="brands"
                                v-model="brand_id"
                            >
                                <option
                                    v-for="(brand, index) in brands"
                                    :key="index"
                                    :value="brand.id"
                                >
                                    {{ brand.name_en }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <h6 v-if="noImage" class="error">
                There must be at least one image!
            </h6>
            <img-upload
                :images="images"
                :cover="true"
                @image_uploaded="saveImage"
                v-if="imageUpload"
                title="Upload Images"
                :key="imagesCounter"
                @getCover="cover = $event"
            />
            <div class="optionBtns">
                <vs-button
                    :disabled="invalidForm"
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="confirmSaveData"
                    >Save
                </vs-button>
                <vs-button
                    class="ml-5 cancel"
                    type="border"
                    color="primary"
                    @click="resetData"
                    >Cancel
                </vs-button>
                <vs-button
                    class="ml-5"
                    type="border"
                    @click="showHTML = !showHTML"
                    >{{ showHTML ? "Hide HTML" : "Show HTML" }}</vs-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImgUpload from "../../../components/general/img-upload";
import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
import Breadcrumb from "../../../components/general/breadcrumb";

export default {
    name: "add-news",
    components: { Breadcrumb, ImgUpload, HomeIcon, ChevronsRightIcon },
    props: {},
    data() {
        return {
            showHTML: false,
            brands: [],
            relatedToBrand: false,
            imageUpload: true,
            baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
            title_ar: "",
            cover: null,
            text_ar: "",
            title_en: "",
            text_en: "",
            images: [],
            mobile_active: true,
            web_active: true,
            type: 0,
            brand_id: 0,
            imagesCounter: 0,
            editor: ClassicEditor,
            editorConfigAr: {
                placeholder: "تفاصيل",
                language: "ar",
            },
            editorConfigEn: {
                placeholder: "Details",
                language: "en",
            },
            imageData: {
                category: "config",
            },
            uploadImageHeaders: {
                "Access-Control-Allow-Origin": "*",
            },
            flags: {
                // lengthExceeded_ar: false,
                // lengthExceeded_en: false
            },
        };
    },
    methods: {
        backToList() {
            this.$emit("cancel");
        },
        getBrands() {
            this.$vs.loading();
            this.$httpCars
                .get(`/brands`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.brands = res.data.data.brands;
                    console.log(this.brands);
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        saveImage(imagesArray) {
            this.images = imagesArray;
            console.log("ImagesArray: ", this.images);
            console.log("Cover: ", this.cover);
            this.imagesCounter++;
        },
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                text: "You are going to save these data!",
                color: "primary",
                accept: () => this.saveData(),
                type: "confirm",
            });
        },
        resetData() {
            this.images = [];
            this.$emit("cancel");
        },
        saveData() {
            this.$vs.loading();

            let images = this.images.map((url) =>
                url.split(this.baseImagesURL).pop()
            );

            let formData = new FormData();

            if (this.title_en) {
                formData.append("title_en", this.title_en);
            }

            if (this.title_ar) {
                formData.append("title_ar", this.title_ar);
            }

            if (this.text_en) {
                formData.append("desc_en", this.text_en);
            }
            if (this.text_ar) {
                formData.append("desc_ar", this.text_ar);
            }
            if (this.mobile_active) {
                formData.append("mobile_active", "1");
            } else {
                formData.append("mobile_active", "0");
            }

            if (this.type) {
                formData.append("type", "1");
                formData.append("brand_id", this.brand_id);
            } else {
                formData.append("type", "0");
            }

            for (let i = 0; i < images.length; i++) {
                let image = {
                    cover: this.cover == i ? "1" : "0",
                    image: images[i],
                };

                formData.append(`images[${i}][image]`, image.image);
                formData.append(`images[${i}][cover]`, image.cover);
            }

            for (const entry of formData.entries()) {
                console.log(entry);
            }

            this.$httpNews
                .post(`/news`, formData)
                .then((r) => {
                    this.$emit("saved");
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Saved",
                        text: "Data is Saved Successfully",
                        color: "primary",
                    });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Error is occurred!\nCouldn't Save Data!",
                        color: "danger",
                    });
                });
        },
    },
    computed: {
        invalidForm() {
            if (this.noImage) {
                return true;
            } else {
                if (this.type) {
                    if (
                        this.brand_id == 0 ||
                        this.brand_id == "null" ||
                        !this.brand_id
                    ) {
                        return true;
                    }
                }
                return false;
            }
        },
        noImage() {
            if (typeof this.images === "undefined") {
                return true;
            }
            return this.images.length === 0;
        },
        newstype() {
            return this.type === "Generaic";
        },
        brandID() {
            if (this.newstype) {
                return null;
            }
            return this.brand_id;
        },
    },
    watch: {},
    mounted() {
        this.getBrands();
    },
};
</script>

<style scoped lang="scss">
@import "../../basic-styles/mixins";

%flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
}

.images {
    @include margin-y(30px);
    text-align: center;
}

.optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
        position: relative;
        left: 25px;
    }
}

.input {
    width: 100%;
    @include margin-y(30px);
}

.arabic,
.english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
}

h1 {
    margin-bottom: 25px;
}

.inputs {
    @extend %flex;
}

ul {
    li {
        display: inline;
    }
}

.clr {
    clear: both;
}

$primaryColor: #054f7c;

.breadcrumb {
    margin: 20px;
}

.back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
}

.main-component {
    color: $primaryColor;
}

.required {
    color: red;
    font-size: 1.5rem;
}

.mandatory {
    color: red;
}

.arabic,
.english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px;
}

.custom-class {
    color: $primaryColor;
}

.title {
    font-size: 2rem;
    font-weight: 400;
}

.separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
}

.label {
    color: #00000099;
    padding-left: 5px;
}

.settings {
    margin: 30px 0 30px 30px;

    .header {
        margin-bottom: 10px;
    }
}

label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
}

select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.centered {
    text-align: center;
}

textarea {
    width: 100%;
    height: 350px;
}
</style>
